<template>
  <div id="phoneBook">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li>
        <router-link :to="{name: 'PhoneBook'}">Imenik zdravstvenih ustanov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">
        <span v-if="$route.params.phoneBookId">Urejanje zapisa v telefonskem imeniku</span>
        <span v-if="!$route.params.phoneBookId">Dodajanje zapisa v telefonski imenik</span>
      </li>
    </vs-breadcrumb>


    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">

        <div class="grid grid-cols-1 mb-10">
          <div>
            <h1>
              <span v-if="$route.params.phoneBookId">Urejanje zapisa v telefonskem imeniku</span>
              <span v-if="!$route.params.phoneBookId">Dodajanje zapisa v telefonski imenik</span>
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <vs-input class="w-full" label="Naziv zdravstvene ustanove" type="text"
                      v-model="phoneBookEntry.name"/>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <vs-input class="w-full" label="Spletno mesto zdravstvene ustanove" type="text"
                      v-model="phoneBookEntry.website"/>
          </div>
        </div>


        <div class="vx-card mt-5">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Telefonske številke</h4>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

        <div class="mb-10">

          <div class="grid grid-cols-1">

            <table class="vs-table vs-table--tbody-table">
              <thead class="vs-table--thead">
              <tr>
                <th style="width: 40px;" class="text-center">#</th>
                <th>Telefonska številka</th>
                <th>Opomba</th>
                <th>Akcije</th>
              </tr>
              </thead>
              <tbody class="vs-table--tbody">
              <tr class="tr-values" v-for="(phoneItem,index) in phoneBookEntry.phones" :key="'phoneItem_' + index"
                  v-if="phoneBookEntry && phoneBookEntry.phones">
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ phoneItem.phone }}</td>
                <td>{{ phoneItem.note }}</td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-button size="small" color="primary" type="border" icon="edit"
                               @click="editPhone(phoneItem, index)"
                               class="my-5 mx-2">Uredi
                    </vs-button>
                    <vs-button size="small" color="danger" type="border" icon="remove"
                               @click="deletePhone(phoneItem, index)"
                               class="my-5 mx-2">Izbriši
                    </vs-button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <vs-button size="small" color="success" type="border" icon="add" @click="showAddPhoneModal()" class="">
          Dodaj
          telefonsko številko
        </vs-button>

            </div>
          </div>
        </div>

        <div class="vx-card mt-5">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Naslov ustanove</h4>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="grid grid-cols-1">
                <label for="customersAddress" class="vs-input--label">Naslov</label>

                <gmap-autocomplete
                  ref="customersAddress"
                  id="customersAddress"
                  @place_changed="(data) => { getAddressData(data, 'customers') }"
                  placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                  :value="phoneBookEntry.address.quick_search"
                  :options="{componentRestrictions: { country: 'si' }}"
                  :select-first-on-enter="true"
                  class="vs-inputx vs-input--input normal"></gmap-autocomplete>
              </div>

              <div slot="header" class="p-0 text-primary mt-5">
                Podrobnosti naslova
              </div>

              <div class="collapseBody mt-5">

                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Ulica" v-model="phoneBookEntry.address.route"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Hišna številka"
                              v-model="phoneBookEntry.address.street_number"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Naziv pošte" v-model="phoneBookEntry.address.postal_town"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Poštna številka"
                              v-model="phoneBookEntry.address.postal_code"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Občina" v-model="phoneBookEntry.address.municipality"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Država" v-model="phoneBookEntry.address.country"/>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="flex mt-10 mb-20 mt-20">
          <div class="w-1/2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="phoneBookEntry.id">
                <vs-button size="large" color="danger" @click="beforeDeletePhoneEntry" icon="delete">Izbriši</vs-button>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" @click="savePhoneBookEntry()">Shrani</vs-button>
              </vs-col>
            </div>
          </div>
        </div>

      </div>
    </div>


    <vs-popup :title="currentPhoneId !== null ? 'Urejanje številke' : 'Dodajanje telefonske številke'"
              :active.sync="editPhoneNumberModal">
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-input class="w-full" label="Telefonska številka" type="text"
                    v-model="currentPhoneNumber"/>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-textarea class="w-full" label="Opomba" type="text"
                       v-model="currentNote"/>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-button size="medium" color="success" icon="check" @click="addPhoneNumber()">
            <span v-if="currentPhoneId !== null">Shrani spremembe</span>
            <span v-if="currentPhoneId === null">Dodaj številko</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import generateUniqueId from "generate-unique-id";


export default {
  name: 'PhoneBookEdit',

  components: {
    'v-select': vSelect,
    Datepicker,
  },

  data() {
    return {
      phoneBookEntry: {
        name: null,
        website: null,
        address: {},
        phones: [],
      },

      editPhoneNumberModal: false,
      currentPhoneIdx: null,
      currentPhoneId: null,
      currentPhoneNumber: null,
      currentNote: null
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    if (_this.$route.params.phoneBookId) {
      _this.getPhoneBookEntry();
    } else {
      _this.phoneBookEntry.address.quick_search = "";
    }
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    next();
  },

  methods: {
    async getPhoneBookEntry() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'v2/contact/' + _this.$route.params.phoneBookId)
        .then((res) => {
          _this.phoneBookEntry = _.filter(res.data.data, {id: parseInt(_this.$route.params.phoneBookId)})[0];
          _this.phoneBookEntry.phones = JSON.parse(_this.phoneBookEntry.phones);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    savePhoneBookEntry() {
      const _this = this;

      _this.$vs.loading();

      let phoneBookEntry = {
        id: _this.phoneBookEntry.id,
        name: _this.phoneBookEntry.name,
        phones: JSON.stringify(_this.phoneBookEntry.phones),
        website: _this.phoneBookEntry.website,
        note: _this.phoneBookEntry.website,
        address: _this.phoneBookEntry.address
      };

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'v2/contact', phoneBookEntry)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'PhoneBook'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    showAddPhoneModal() {
      const _this = this;

      _this.currentPhoneId = null;
      _this.currentPhoneIdx = null;
      _this.currentPhoneNumber = null;
      _this.currentNote = null;
      _this.editPhoneNumberModal = true;
    },

    editPhone(phoneItem, idx) {
      const _this = this;

      _this.currentPhoneId = phoneItem.id;
      _this.currentPhoneIdx = idx;
      _this.currentPhoneNumber = phoneItem.phone;
      _this.currentNote = phoneItem.note;
      _this.editPhoneNumberModal = true;
    },

    deletePhone(phoneItem, idx) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati telefonsko številko: ' + phoneItem.phone + '?',
        message: 'Po potrditvi bo vnos izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.phoneBookEntry.phones.splice(idx, 1);
          }
        }
      });


    },

    addPhoneNumber() {
      const _this = this;

      if (_this.currentPhoneId) {
        _this.phoneBookEntry.phones[_this.currentPhoneIdx] = {
          id: _this.currentPhoneId,
          note: _this.currentNote,
          phone: _this.currentPhoneNumber
        };
      } else {
        _this.phoneBookEntry.phones.push({
          id: generateUniqueId(),
          note: _this.currentNote,
          phone: _this.currentPhoneNumber,
        });
      }
      _this.editPhoneNumberModal = false;
    },

    beforeDeletePhoneEntry() {

    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData", addressData);

      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.phoneBookEntry.address.quick_search = addressData.formatted_address;
      _this.phoneBookEntry.address.route = addressData.address_components[1].long_name;
      _this.phoneBookEntry.address.street_number = addressData.address_components[0].long_name;
      _this.phoneBookEntry.address.postal_town = addressData.address_components[2].long_name;
      _this.phoneBookEntry.address.postal_code = addressData.address_components[5].long_name;
      _this.phoneBookEntry.address.municipality = addressData.address_components[3].long_name;
      _this.phoneBookEntry.address.country = addressData.address_components[4].long_name;


      _this.$forceUpdate();
    },
  }
}
</script>

